<template>
  <material-card
    class="v-card--material-chart mb-4"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #header>
      <chartist
        v-if="library === 'chartist'"
        :data="data"
        :event-handlers="eventHandlers"
        :options="options"
        :ratio="ratio"
        :responsive-options="responsiveOptions"
        :type="type"
        style="max-height: 260px;"
      />

      <div
        v-else-if="library === 'apexcharts'"
        style="width: 100%;"
      >
        <apexchart
          :options="options"
          :series="series"
          :type="type"
          :height="chartHeight"
          :class="chartClass"
        />
      </div>
    </template>

    <slot />

    <slot
      slot="actions"
      name="actions"
    />
  </material-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';

  export default {
    name: 'MaterialChartCard',
    components : {
      apexchart: VueApexCharts,
    },

    inheritAttrs: false,

    props: {
      library: {
        type: String,
        default: 'chartist',
      },
      data: {
        type: Object,
        default: () => ({}),
      },
      series: {
        type: Array,
        default: () => ([]),
      },
      eventHandlers: {
        type: Array,
        default: () => ([]),
      },
      options: {
        type: Object,
        default: () => ({}),
      },
      ratio: {
        type: String,
        default: undefined,
      },
      responsiveOptions: {
        type: Array,
        default: () => ([]),
      },
      type: {
        type: String,
        required: true,
        validator: v => ['Bar', 'Line', 'Pie'].includes(v),
      },
      chartHeight: {
        type: String,
        default: '280',
      },
      chartClass: {
        type: String,
        default: 'mt-n5',
      },
    },
  }
</script>

<style lang="scss">
  .v-card--material-chart {
    .v-card--material__header {
      .ct-label {
        color: inherit;
        opacity: .7;
        // font-size: 0.975rem;
        font-weight: 300;
      }

      .ct-grid{
        stroke: rgba(255, 255, 255, 0.2);
      }
      .ct-series-a .ct-point,
      .ct-series-a .ct-line,
      .ct-series-a .ct-bar,
      .ct-series-a .ct-slice-donut {
          stroke: rgba(255,255,255,.8);
      }
      .ct-series-a .ct-slice-pie,
      .ct-series-a .ct-area {
          fill: rgba(255,255,255,.4);
      }
      .ct-bar {
        stroke-width: 4.5%;
      }
      .chartist-tooltip {
        position: absolute;
        background: rgba(0,0,0,0.6);
        color: white;
        padding: 0px 10px;
        border-radius: 5px;
      }
      .ct-point-label {
        position: absolute;
        background: rgba(0,0,0,0.6);
        color: white;
        padding: 0px 10px;
        border-radius: 5px;
      }
    }
  }
</style>
